<template>
  <ion-item class="message-form ion-no-padding">
		<div class="editools" :style="{display: edtls ? 'flex' : 'none'}">
			<span @click="addTag('B')" title="Жирный"><b>B</b></span>
			<span @click="addTag('I')" title="Курсив"><i>I</i></span>
			<span @click="addTag('U')" title="Подчеркнутый"><u>U</u></span>
			<span @click="addTag('S')" title="Зачеркнутый"><s>S</s></span>
			<span @click="addTag('H')" title="Подзаголовок"><b>H</b></span>
			<span @click="addTag('OFF')" title="Офф-топик">OFF</span>
			<span @click="addTag('JUSTIFY')" title="По ширине"><fa icon="align-justify" class="fas-icon"></fa></span>
			<span @click="addTag('CENTER')" title="По центру"><fa icon="align-center" class="fas-icon"></fa></span>
			<span @click="addTag('RIGHT')" title="По правому краю"><fa icon="align-right" class="fas-icon"></fa></span>
			<span v-if="false" @click="addTag('SPOILER')" title="Скрыть текст за ссылкой 'Подробнее...'">SPOILER</span>
			<span @click="modalShow = true"><fa icon="image" class="fas-icon"></fa></span>
			<span @click="uploadModalShow = true"><fa icon="cloud-upload-alt" class="fas-icon"></fa></span>
			<AudioLink @audio-add="addAudioLink"></AudioLink>
			<VideoLink @video-add="addVideoLink"></VideoLink>
			<InstaLink @insta-add="addInstaLink"></InstaLink>
			<UserLink @user-add="addUserLink"></UserLink>
			<span @click="getQuotes()" title="Вставить цитату"><fa icon="quote-left" class="fas-icon"></fa></span>
			<span @click="msgform = ''" title="Очистить форму"><fa icon="trash-alt" class="fas-icon"></fa></span>
		</div>
		<span @click="updEdtls" style="display:inline-block;height: 100%;width:32px;text-align:center;border:1px solid black">{{edtls ? 'x' : '+'}}</span>
		<span class="smiles-dropdown" style="display:inline-block;height: 100%;width:32px;text-align:center;border:1px solid black">
			<img src="//beon.fun/i/smiles/smile.png">
			<div class="dropdown-content">
				<img v-for="sml in smilesArr" :key="sml" :src="'//beon.fun/i/smiles/' + sml[0] + '.png'" :data-smile="sml[1]" @click="addSmile($event)">
			</div>
		</span>
		<ion-textarea placeholder="Добавить комментарий" v-model="msgform" class="itextarea" v-bind:class="{ 'textarea-xpnd': edtls }"></ion-textarea>
		<ion-button color="primary" class="send-button" @click="sendMsg()">></ion-button>
	</ion-item>
	
	<div v-bind:class="{'modal-fade': true, show: modalShow}">
		<div class="modal">
			<div>Добавить изображение по ссылке</div>
			<div><ion-textarea v-model="imagelink[0]" placeholder="http://"></ion-textarea></div>
			<ion-item>
				<ion-label>Размер</ion-label>
				<ion-select v-model="imagelink[1]" @ionChange="imagelink[1]= $event.target.value">
					<ion-select-option value="original">Оригинал</ion-select-option>
					<ion-select-option value="medium">320px</ion-select-option>
					<ion-select-option value="small">200px</ion-select-option>
				</ion-select>
			</ion-item>
			<ion-item>
				<ion-label>Выравнивание</ion-label>
				<ion-select v-model="imagelink[2]" @ionChange="imagelink[2]= $event.target.value">
					<ion-select-option value="none">Нет</ion-select-option>
					<ion-select-option value="left">Влево</ion-select-option>
					<ion-select-option value="right">Вправо</ion-select-option>
				</ion-select>
			</ion-item>
			<div>
				<ion-button color="medium" @click="modalShow = false">Отмена</ion-button><ion-button @click="modalPicLink">Прикрепить</ion-button>
			</div>
		</div>
	</div>
	
	<div v-bind:class="{'modal-fade': true, show: uploadModalShow}">
		<div class="modal">
			<div>Выгрузить изображения с телефона</div>
			<div class="form-error">{{formError}}</div>
			<div><ion-input type="file" name="photo" @change="filePickerUpdated" multiple></ion-input></div>
			<ion-item>
				<ion-label>Размер</ion-label>
				<ion-select v-model="imageupload[0]" @ionChange="imageupload[0]= $event.target.value">
					<ion-select-option value="original">Оригинал</ion-select-option>
					<ion-select-option value="medium">320px</ion-select-option>
					<ion-select-option value="small">200px</ion-select-option>
				</ion-select>
			</ion-item>
			<ion-item>
				<ion-label>Выравнивание</ion-label>
				<ion-select v-model="imageupload[1]" @ionChange="imageupload[1]= $event.target.value">
					<ion-select-option value="none">Нет</ion-select-option>
					<ion-select-option value="left">Влево</ion-select-option>
					<ion-select-option value="right">Вправо</ion-select-option>
				</ion-select>
			</ion-item>
			<div>
				<ion-button color="medium" @click="uploadModalShow = false">Отмена</ion-button><ion-button @click="modalPicUpload">Прикрепить</ion-button>
			</div>
		</div>
	</div>
</template>


<style>
	.textarea-wrapper, .textarea-wrapper > textarea {
		height:100%;
	}
	.fas-icon, .editools span {
		height: 1.5rem;
		font-size: 1.5rem;
	}
	.modal-fade {
		position: fixed;
		top:-100%;
		width: 100%;
		height: 100%;
		background: #2c2c2ccc;
		z-index: 99;
		display: flex;
	}
	.modal-fade.show {
		top: 0;
	}
	.modal {
		margin: auto;
		background: white;
		width: 50%;
		min-width:320px;
		height: 50%;
		min-height: 320px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
	}
	.modal > * {
		text-align:center;
		width:90%;
	}
	.editools {
		display:flex;
		overflow-x: scroll;
		padding: 1rem 0.5rem;
		position: absolute;
		bottom: 100%;
		width: 100%;
		background: #ffffff;
	}
	.editools span {
		margin-right: .5rem;
	}
	.smiles-popover img {
		margin: 10px;
	}

	.smiles-dropdown {
		position: relative;
		display: inline-block;
	}
	.dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		width: 60vw;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		padding: 12px 16px;
		z-index: 9;
		bottom: 100%;
	}

	.smiles-dropdown:hover .dropdown-content {
		display: block;
	}
	.dropdown-content img {
		margin:10px;
	}
	.message-form {
		position: fixed;
		bottom: 0;
		width: 100%;
		border: 1px solid blue;
		overflow: initial;
	}
	ion-textarea.itextarea.textarea-xpnd {
		height: 100px;
	}
</style>

<script lang="ts">
//import { alertController, toastController } from '@ionic/vue';
import { IonItem, IonButton, IonTextarea, IonLabel, IonInput, IonSelect, IonSelectOption, } from '@ionic/vue';
import { ref, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";
import UserLink from '@/views/UserLink.vue';
import AudioLink from '@/views/AudioLink.vue';
import VideoLink from '@/views/VideoLink.vue';
import InstaLink from '@/views/InstaLink.vue';

//import { defineComponent } from 'vue';
export default {
	name: 'MessageField',
	components: {
		IonItem, IonButton, IonTextarea,
		UserLink, AudioLink, VideoLink, InstaLink,
		IonSelect, IonSelectOption, IonLabel, IonInput
	},
	props: ['sendpoint', 'passquote'],
	methods: {
		
	},
	emits: ['msg-sended'],
	setup(props: any, {emit}: any){
		const { token } = useDataService();
		const msgform = ref("");
		watch(() => props.passquote, () => {
			if(props.passquote){
				msgform.value += props.passquote;
			}
		});
		const addUserLink = function(e: any){
			msgform.value += "[user-" + e + "]";
		};
		const addImageLink = function(e: any){
			msgform.value += "[image-original-none-" + e + "]";
		};
		const addAudioLink = function(e: any){
			msgform.value += e;
		};
		const addVideoLink = function(e: any){
			msgform.value += e;
		};
		const addInstaLink = function(e: any){
			msgform.value += e;
		};
		const addSmile = function(event: any){
			const smlCode = event.currentTarget.getAttribute('data-smile');
			msgform.value += " " + smlCode + " ";
		};
		const addTag =  function(tag: string){
			msgform.value += '[' + tag + '][/' + tag + ']';
		};
		const smilesArr = [
			['smile',':-)'],
			['sad',':-('],
			['wink',';-)'],
			['kiss',':-*'],
			['big-smile',':-D'],
			['surprised',':-O'],
			['tongue-sticking-out',':-P'],
			['angry','X-('],
			['devil',']:-)'],
			['angel','O:-)'],
			['cry',":'("],
			['upset',':-['],
			['confused',':-\\'],
			['undecided',':-|'],
			['thinking',':-?'],
			['cunning',';~)'],
			['tired','(:|'],
			['crazy','8-}'],
			['shhh',':-$'],
			['shocked','8-|'],
			['sun-glasses','B-)'],
			['turn-red',':^)'],
			['thumbs-up','=^B'],
			['thumbs-down','=,B']
		];
		const sendMsg = function(){
			if(msgform.value.length){
				axios.post(props.sendpoint,{
					'message': msgform.value
				},{
				headers: {
					Authorization: 'Bearer ' + token.value,
				}})
				.then((response) => {
					if(response.data.status == 'ok'){
						msgform.value = "";
						emit('msg-sended');
					}
				});
			}
		};
		
		const modalShow = ref(false);
		const uploadModalShow = ref(false);
		const imagelink = ref(['','original','none']);
		const imageupload = ref(['original','none']);
		const modalPicLink = function(){
			if(/^https?:\/\/.*\..*\/.*/.test(imagelink.value[0])){
				msgform.value += " [image-" + imagelink.value[1] + "-" + imagelink.value[2] + "-" + imagelink.value[0] + "]";
				modalShow.value = false;
				imagelink.value[0] = "";
			}
		};
		
		const uploadFiles = ref<any>(null);
		const formError = ref<string>('');
		const modalPicUpload = function(){
			formError.value = '';
			const formData = new FormData();
			for(let i=0;i<uploadFiles.value.length;i++){
				formData.append('image[]', uploadFiles.value[i]);
			}
			
			axios.post( 'https://beon.fun/api/v1/upload/images',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						'Authorization': 'Bearer ' + token.value,
					}
				}
			).then((response) => {
				console.log('SUCCESS!!');
				if(response.data.status === 'ok' && response.data.fileurls.length){
					response.data.fileurls.forEach((item: any) => {
						msgform.value += '[img-' + imageupload.value[0] + '-' + imageupload.value[1] + '-' + item + ']';
					});
					uploadModalShow.value = false;
				} else {
					formError.value = 'Ошибка при загрузке файла';
				}
			})
			.catch(function(){
				formError.value = 'Ошибка при загрузке файла';
			});
			
			uploadModalShow.value = false;
		};
		const filePickerUpdated = function(event: any){
			uploadFiles.value = event.target.files;
			console.log(event.target.files);
		}
		const edtls = ref(false);
		const updEdtls = function(){
			edtls.value = !edtls.value;
		};
		return {msgform, addUserLink, addImageLink, addAudioLink, addVideoLink, addInstaLink, imagelink, imageupload, modalShow, uploadModalShow, modalPicLink, formError, filePickerUpdated,  modalPicUpload, smilesArr, edtls, updEdtls, addTag, addSmile, sendMsg }
	}
}

</script>