
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList} from '@ionic/vue';
import { globeOutline, globeSharp } from 'ionicons/icons';
import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";
import MessageField from '@/views/MessageField.vue';

export default {
	name: 'Post',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
		MessageField
	},
	data() {
		return {
			countz: 0,
		}
	},
	methods: {
		getUserPath: function(user: any){
			return "/user/" + user.name;
		},
		getUserAvatar: function(user: any){
			return user.avatar ? "//i0.beon.fun/" + user.path + "/avatars/" + user.avatar.idx + "." + user.avatar.ext : '';
		}
	},
	setup() {
		const route = useRoute();
		const folder = ref(route.params.id || 'Inbox');
		const matchedFolder = computed(() => route.params.id);
		let chkTimer: number;
		const { token, getRichText, doLike } = useDataService();
		
		watch(route, () => {
			clearTimeout(chkTimer);
		});
		
		watch(matchedFolder, () => {
			folder.value = matchedFolder.value as string;
		});
		const comments = ref<any>([]);
		const post = ref(null);
		
		let gettingNew = false;
		
		const playOk = function(){
			const audio = new Audio('/sounds/notify.wav');
			audio.play();
		};
		const getNew = function(){
			if(!gettingNew){
				gettingNew = true;
				let lastmsg;
				if (comments.value[comments.value.length-1] !== undefined){
					lastmsg = comments.value[comments.value.length-1].postcommentid;
				} else {
					lastmsg = 0;
				}
				axios.get('https://beon.fun/api/v1/blog/'+route.params.blogslug+'/post/'+route.params.id+'/get?last='+lastmsg,{
				headers: {
					Authorization: 'Bearer ' + token.value,
				}})
				.then(response => (
					response.data.forEach((item: any) => {comments.value.push(item)}),
					playOk()
				)).finally(() => (gettingNew = false));
			}
		};
		
		const checkNew = function(){
			let lastmsg;
			if (comments.value[comments.value.length-1] !== undefined){
				lastmsg = comments.value[comments.value.length-1].postcommentid;
			} else {
				lastmsg = 0;
			}
			axios.get('https://beon.fun/api/v1/blog/'+route.params.blogslug+'/post/'+route.params.id+'/check?last='+lastmsg,{
			headers: {
				Authorization: 'Bearer ' + token.value,
			}})
			.then((response) => {
				if(response.data.new == 1){
					getNew();
				}
			});
		};
		
		axios.get('https://beon.fun/api/v1/blog/'+route.params.blogslug+'/post/'+route.params.id,{
		headers: {
			Authorization: 'Bearer ' + token.value,
		}})
		.then(response => (
			response.data.comments.forEach((item: any) => {comments.value.push(item)}),
			post.value = response.data.post,
			folder.value =  response.data.post.title,
			chkTimer = setInterval(checkNew, 30000)
		));
		const getQuotes = function(){
			const text: any = window.getSelection();
			//msgform.value += text.toString();
		};
		const passquote = ref<string>("");
		const getQuote = function(e: any){
			passquote.value = "\r\n" + e.currentTarget.parentNode.childNodes[0].innerText.replace(/^/g,'> ').replace(/\n/g,'\n> ');
		};
		const sendpoint = 'https://beon.fun/api/v1/blog/'+route.params.blogslug+'/post/'+route.params.id;
		
		return { folder, comments, post, getQuotes, getQuote, sendpoint, getNew, passquote, getRichText, doLike }
	}
}
