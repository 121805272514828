<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ folder }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ folder }}</ion-title>
        </ion-toolbar>
      </ion-header>
    
		<div id="container">
			<ion-list>
				<ion-item v-if="post">
					<div class="post">
						<div class="post__header">
							<span class="post__date">{{post.created_at}}</span>
						</div>
						<div class="post__body">
							<div class="post__author" @click="() => $router.push(getUserPath(post.user))">
								<img v-bind:src="getUserAvatar(post.user)">
								<div class="post__username">{{post.user.nickname}}</div>
								<div class="post__sign">{{post.user.sign}}</div>
							</div>
							<div class="post__text selectable">
								<div class="post__text_content" v-html="getRichText(post.text)"></div>
								<div class="like-wrapper" :class="{liked: post.liked.length}" @click="doLike(post, 'post')">
									<fa icon="heart" class="fas-icon"></fa>
								</div>
							</div>
						</div>
					</div>
				</ion-item>
				<ion-item class="ion-no-padding" v-for="item in comments" v-bind:key='item.id'>
					<div class="post">
						<div class="post__header">
							<span class="post__date">{{item.created_at}}</span>
						</div>
						<div class="post__body">
							<div class="post__author" @click="() => $router.push(getUserPath(item.user))">
								<img v-bind:src="getUserAvatar(item.user)">
								<div class="post__username">{{item.user.nickname}}</div>
								<div class="post__sign">{{item.user.sign}}</div>
							</div>
							<div class="post__text selectable">
								<div class="post__text_content" v-html="getRichText(item.text)"></div>
								<div class="fast-quote" @click="getQuote">> Quote</div>
								<div class="like-wrapper" :class="{liked: item.liked.length}" @click="doLike(item)">
									<fa icon="heart" class="fas-icon"></fa>
								</div>
							</div>
						</div>
						
					</div>
				</ion-item>
			</ion-list>
			<MessageField v-bind:sendpoint="sendpoint" v-bind:passquote="passquote" v-if="post && post.can_comment" @msg-sended="getNew"></MessageField>
		</div>
		
		
    </ion-content>
	</ion-page>
	
	
</template>

<style scoped>
ion-menu-button {
  color: var(--ion-color-primary);
}
ion-item{
    --inner-padding-end: 0.5%;
    --padding-start:0.5%;
	padding-bottom: 20px;
}
.post {
	padding-bottom: 20px;
}
.post__title {
    font-weight: bold;
	font-size: 120%;
}
.post__date {
	color: #808080;
    font-size: 80%;
    margin-left: 1rem;
}
.post__body {
    display: flex;
}
.post__text {
    flex-grow: 1;
	word-break: break-word;
}
.post__author {
    text-align: center;
	margin-right: 1%;
	max-width: 120px;
}
.post__sign {
    font-size: 80%;
    color: #808080;
}
.fast-quote {
	margin-top: 25px;
	color: #808080;
}
.like-wrapper {
	margin-top: 1em;
}
.like-wrapper.liked {
	color: red;
}
</style>

<style>
	.fas-icon, .editools span {
		height: 1.5rem;
		font-size: 1.5rem;
	}
</style>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList} from '@ionic/vue';
import { globeOutline, globeSharp } from 'ionicons/icons';
import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";
import MessageField from '@/views/MessageField.vue';

export default {
	name: 'Post',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
		MessageField
	},
	data() {
		return {
			countz: 0,
		}
	},
	methods: {
		getUserPath: function(user: any){
			return "/user/" + user.name;
		},
		getUserAvatar: function(user: any){
			return user.avatar ? "//i0.beon.fun/" + user.path + "/avatars/" + user.avatar.idx + "." + user.avatar.ext : '';
		}
	},
	setup() {
		const route = useRoute();
		const folder = ref(route.params.id || 'Inbox');
		const matchedFolder = computed(() => route.params.id);
		let chkTimer: number;
		const { token, getRichText, doLike } = useDataService();
		
		watch(route, () => {
			clearTimeout(chkTimer);
		});
		
		watch(matchedFolder, () => {
			folder.value = matchedFolder.value as string;
		});
		const comments = ref<any>([]);
		const post = ref(null);
		
		let gettingNew = false;
		
		const playOk = function(){
			const audio = new Audio('/sounds/notify.wav');
			audio.play();
		};
		const getNew = function(){
			if(!gettingNew){
				gettingNew = true;
				let lastmsg;
				if (comments.value[comments.value.length-1] !== undefined){
					lastmsg = comments.value[comments.value.length-1].postcommentid;
				} else {
					lastmsg = 0;
				}
				axios.get('https://beon.fun/api/v1/blog/'+route.params.blogslug+'/post/'+route.params.id+'/get?last='+lastmsg,{
				headers: {
					Authorization: 'Bearer ' + token.value,
				}})
				.then(response => (
					response.data.forEach((item: any) => {comments.value.push(item)}),
					playOk()
				)).finally(() => (gettingNew = false));
			}
		};
		
		const checkNew = function(){
			let lastmsg;
			if (comments.value[comments.value.length-1] !== undefined){
				lastmsg = comments.value[comments.value.length-1].postcommentid;
			} else {
				lastmsg = 0;
			}
			axios.get('https://beon.fun/api/v1/blog/'+route.params.blogslug+'/post/'+route.params.id+'/check?last='+lastmsg,{
			headers: {
				Authorization: 'Bearer ' + token.value,
			}})
			.then((response) => {
				if(response.data.new == 1){
					getNew();
				}
			});
		};
		
		axios.get('https://beon.fun/api/v1/blog/'+route.params.blogslug+'/post/'+route.params.id,{
		headers: {
			Authorization: 'Bearer ' + token.value,
		}})
		.then(response => (
			response.data.comments.forEach((item: any) => {comments.value.push(item)}),
			post.value = response.data.post,
			folder.value =  response.data.post.title,
			chkTimer = setInterval(checkNew, 30000)
		));
		const getQuotes = function(){
			const text: any = window.getSelection();
			//msgform.value += text.toString();
		};
		const passquote = ref<string>("");
		const getQuote = function(e: any){
			passquote.value = "\r\n" + e.currentTarget.parentNode.childNodes[0].innerText.replace(/^/g,'> ').replace(/\n/g,'\n> ');
		};
		const sendpoint = 'https://beon.fun/api/v1/blog/'+route.params.blogslug+'/post/'+route.params.id;
		
		return { folder, comments, post, getQuotes, getQuote, sendpoint, getNew, passquote, getRichText, doLike }
	}
}
</script>