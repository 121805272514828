
//import { alertController, toastController } from '@ionic/vue';
import { IonItem, IonButton, IonTextarea, IonLabel, IonInput, IonSelect, IonSelectOption, } from '@ionic/vue';
import { ref, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";
import UserLink from '@/views/UserLink.vue';
import AudioLink from '@/views/AudioLink.vue';
import VideoLink from '@/views/VideoLink.vue';
import InstaLink from '@/views/InstaLink.vue';

//import { defineComponent } from 'vue';
export default {
	name: 'MessageField',
	components: {
		IonItem, IonButton, IonTextarea,
		UserLink, AudioLink, VideoLink, InstaLink,
		IonSelect, IonSelectOption, IonLabel, IonInput
	},
	props: ['sendpoint', 'passquote'],
	methods: {
		
	},
	emits: ['msg-sended'],
	setup(props: any, {emit}: any){
		const { token } = useDataService();
		const msgform = ref("");
		watch(() => props.passquote, () => {
			if(props.passquote){
				msgform.value += props.passquote;
			}
		});
		const addUserLink = function(e: any){
			msgform.value += "[user-" + e + "]";
		};
		const addImageLink = function(e: any){
			msgform.value += "[image-original-none-" + e + "]";
		};
		const addAudioLink = function(e: any){
			msgform.value += e;
		};
		const addVideoLink = function(e: any){
			msgform.value += e;
		};
		const addInstaLink = function(e: any){
			msgform.value += e;
		};
		const addSmile = function(event: any){
			const smlCode = event.currentTarget.getAttribute('data-smile');
			msgform.value += " " + smlCode + " ";
		};
		const addTag =  function(tag: string){
			msgform.value += '[' + tag + '][/' + tag + ']';
		};
		const smilesArr = [
			['smile',':-)'],
			['sad',':-('],
			['wink',';-)'],
			['kiss',':-*'],
			['big-smile',':-D'],
			['surprised',':-O'],
			['tongue-sticking-out',':-P'],
			['angry','X-('],
			['devil',']:-)'],
			['angel','O:-)'],
			['cry',":'("],
			['upset',':-['],
			['confused',':-\\'],
			['undecided',':-|'],
			['thinking',':-?'],
			['cunning',';~)'],
			['tired','(:|'],
			['crazy','8-}'],
			['shhh',':-$'],
			['shocked','8-|'],
			['sun-glasses','B-)'],
			['turn-red',':^)'],
			['thumbs-up','=^B'],
			['thumbs-down','=,B']
		];
		const sendMsg = function(){
			if(msgform.value.length){
				axios.post(props.sendpoint,{
					'message': msgform.value
				},{
				headers: {
					Authorization: 'Bearer ' + token.value,
				}})
				.then((response) => {
					if(response.data.status == 'ok'){
						msgform.value = "";
						emit('msg-sended');
					}
				});
			}
		};
		
		const modalShow = ref(false);
		const uploadModalShow = ref(false);
		const imagelink = ref(['','original','none']);
		const imageupload = ref(['original','none']);
		const modalPicLink = function(){
			if(/^https?:\/\/.*\..*\/.*/.test(imagelink.value[0])){
				msgform.value += " [image-" + imagelink.value[1] + "-" + imagelink.value[2] + "-" + imagelink.value[0] + "]";
				modalShow.value = false;
				imagelink.value[0] = "";
			}
		};
		
		const uploadFiles = ref<any>(null);
		const formError = ref<string>('');
		const modalPicUpload = function(){
			formError.value = '';
			const formData = new FormData();
			for(let i=0;i<uploadFiles.value.length;i++){
				formData.append('image[]', uploadFiles.value[i]);
			}
			
			axios.post( 'https://beon.fun/api/v1/upload/images',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						'Authorization': 'Bearer ' + token.value,
					}
				}
			).then((response) => {
				console.log('SUCCESS!!');
				if(response.data.status === 'ok' && response.data.fileurls.length){
					response.data.fileurls.forEach((item: any) => {
						msgform.value += '[img-' + imageupload.value[0] + '-' + imageupload.value[1] + '-' + item + ']';
					});
					uploadModalShow.value = false;
				} else {
					formError.value = 'Ошибка при загрузке файла';
				}
			})
			.catch(function(){
				formError.value = 'Ошибка при загрузке файла';
			});
			
			uploadModalShow.value = false;
		};
		const filePickerUpdated = function(event: any){
			uploadFiles.value = event.target.files;
			console.log(event.target.files);
		}
		const edtls = ref(false);
		const updEdtls = function(){
			edtls.value = !edtls.value;
		};
		return {msgform, addUserLink, addImageLink, addAudioLink, addVideoLink, addInstaLink, imagelink, imageupload, modalShow, uploadModalShow, modalPicLink, formError, filePickerUpdated,  modalPicUpload, smilesArr, edtls, updEdtls, addTag, addSmile, sendMsg }
	}
}

